import { type AllCountryCode } from '@/constants/country-code'
import { VerifiedKol } from '@/constants/verified-kol'
import {
  ExecutableRequest,
  PageBasedPagination,
  WorkspaceApiBasicRequest,
} from '@/types/api/index'
import { FetchKolResourceBasicRequest } from '@/types/api/kols'
import { Nullable } from '@/types/nullable'
import {
  KolSchemaModel,
  KolSchemaRemote,
  SearchSort,
} from '@/types/schema/kol-schema'
import { PlatformType } from '@/types/schema/report-schema'
import {
  SearchForKolNoResultProperties,
  SearchForKolProperties,
} from '@/utils/ampli'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'

export interface Condition {
  anchor?: string
  avgPvRateBegin?: number
  avgPvRateEnd?: number
  interactiveRateBegin?: number
  interactiveRateEnd?: number
  keyword?: string
  fansUpRateBegin?: number
  fansUpRateEnd?: number
  follower_end_to?: number
  follower_start_from?: number
  from?: string
  gender?: string
  potential?: Potential
  country_code?: AllCountryCode
  t?: number
  customized_tags_names?: string
  platform_type?: PlatformShortcode
  post_end_time?: string
  post_start_time?: string
  labels?: string
  projectId?: string
  sort?: SearchSort
  followerRangeLevel?: number
  filter_collection?: string
  filter_cooperation?: string
  filter_unrecommended?: string
  filter_kol_type?: string
  audienceGenderCode?: string
  audienceGenderRateBegin?: number
  audienceGenderRateEnd?: number
  audienceAgeCode?: string
  audienceAgeRateBegin?: number
  audienceAgeRateEnd?: number
  audienceGeoCode?: string
  audienceGeoRateBegin?: number
  audienceGeoRateEnd?: number
  verifiedKol?: VerifiedKol
  minQuotationPrice?: number
  maxQuotationPrice?: number
  languages?: string[]
  keywordFrom?: SearchForKolNoResultProperties['keywordFrom']
}

export interface SearchSimilarKolsRequest extends FetchKolResourceBasicRequest {
  platform: PlatformShortcode
  per_page: number
  page?: number
}

export interface SearchSimilarKolResponse extends PageBasedPagination {
  list: KolSchemaModel[]
}

export type KolRadarSearchRequestBasicProps = Omit<Condition, 'languages'> & {
  languages?: string
}

export type KolRadarSearchRequestBasicPropKey =
  keyof KolRadarSearchRequestBasicProps

export const KolRadarSearchDefaultSearchKeys: KolRadarSearchRequestBasicPropKey[] =
  ['country_code', 'filter_kol_type', 'sort']

export type KolRadarSearchRequestEventProps = {
  view: SearchForKolProperties['view']
  isDefault: SearchForKolProperties['isDefault']
  aiEnabled: boolean
}

export interface KolRadarSearchRequest
  extends WorkspaceApiBasicRequest,
    ExecutableRequest {
  params: KolRadarSearchRequestBasicProps
}

export interface KOLResponse {
  anchor: string
  list: KolSchemaRemote[]
  total: number
  keywords?: string[]
}

export interface KolRadarSearchResponse extends KOLResponse {
  list: KolSchemaModel[]
}

export interface QuickSearchRequest {
  keyword: string
  limit?: number
  countryCode?: string
  platform?: string
}

export type QuickSearchKOL = {
  uuid: string
  name: string[]
  followerCount: number
  isVerified: boolean
  isRadarStar: boolean
  potential?: Potential
  similarKolPlatform: Nullable<PlatformShortcode>
  customizedTagsNames: string[]
  platformTypeList?: PlatformType[]
  source: KolSource.DataCenter
}

export enum KolSource {
  DataCenter = 1,
  IqData = 2,
}

export type QuickSearchResponse = {
  data: QuickSearchKOL[]
}

export type QuickSearchKolFromIqData = {
  uuid: ''
  name: string[]
  followerCount: number
  isVerified: false
  isRadarStar: false
  potential: undefined
  similarKolPlatform: null
  platformTypeList?: PlatformType[]
  thumbnailUrl: string
  links: {
    url: string
    platformUserID: string
  }[]
  source: KolSource.IqData
}

export type QuickSearchWithExploreResponse = {
  data: (QuickSearchKOL | QuickSearchKolFromIqData)[]
}

interface VerifiedRadarSearch extends Omit<QuickSearchKOL, 'uuid'> {
  UUID: string
}

export type VerifiedRadarSearchResponse = {
  data: VerifiedRadarSearch[]
}
